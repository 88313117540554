<template>
  <div class="box">
    <div class="fix-btn">
      <el-button type="primary" @click="addQuestion" size="small">添加推荐</el-button>
    </div>
    <div class="mg-tp-20 pd-bt-20"></div>
    <div class="question" v-for="(item,index) in dataList" :key="index">
      <div>
        <div class="title" v-if="item.t_type==4">
          标题：{{item.score_title}}<br> 分数区间：{{item.score_start}} ~ {{item.score_end}} 分
        </div>
        <div class="title" v-else-if="item.t_type==3">
          分数区间：{{item.score_start}} ~ {{item.score_end}} 分
        </div>
        <div class="title" v-else>
          <div v-for="(child,cindex) in item.content" :key="cindex">【{{item.t_type==1?'或者':'并且'}}】{{child.title}}：{{child.ttitle}}</div>
        </div>
        <div class="intro">描述：{{item.desc}}</div>
        <div class="recommend">建议：{{item.recommend}}</div>
      </div>
      <div class="goods-box">
        <div class="item" v-for="(child,cindex) in item.book" :key="cindex">
          <img class="img" :src="child.img">
          <div class="name">{{child.title}}</div>
        </div>
      </div>
      <div class="option" v-for="(child,cindex) in item.options" :key="cindex">
        {{letter[cindex]}}、{{child.title}} 【{{child.score}}分】
      </div>
      <div class="opt">
        <el-button type="primary" @click="editRec(index)" size="small">编辑</el-button>
        <el-popconfirm class="mg-lf-10"
            title="确认删除吗？"
            @confirm="delRec(index)"
        >
          <el-button slot="reference" type="danger" size="small">删除</el-button>
        </el-popconfirm>
      </div>
    </div>
    <el-dialog title="编辑推荐" :visible.sync="dialogFormVisible" custom-class="dialog" :close-on-click-modal="false">
      <el-form :model="form" size="small">
        <el-form-item label="推荐类型" :label-width="formLabelWidth">
          <el-radio-group v-model="form.t_type">
<!--            <el-radio :label="1" disabled>或者关系</el-radio>-->
<!--            <el-radio :label="2" disabled>并且关系</el-radio>-->
<!--            <el-radio :label="3" disabled>总分规则</el-radio>-->
            <el-radio :label="4">书单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择选项" :label-width="formLabelWidth" v-if="form.t_type<3">
          <el-button type="primary" @click="OptionVisible=true" size="small">添加选项</el-button>
          <div class="">
            <div class="item" v-for="(item,index) in form.content" :key="index">
              <div class="name">{{item.title}}：{{item.ttitle}} <el-button type="text" @click="delOption(index)" size="small">删除</el-button></div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分数区间" :label-width="formLabelWidth" v-if="form.t_type==3 || form.t_type==4">
          <el-row :gutter="20">
            <el-col :span="7" v-if="form.t_type==4">
              <el-input v-model="form.score_title" autocomplete="off" placeholder="请输入标题">
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-input v-model="form.score_start" autocomplete="off" placeholder="请输入最小分数">
                <template slot="append">分</template>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-input v-model="form.score_end" autocomplete="off" placeholder="请输入最大分数">
                <template slot="append">分</template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input type="textarea" rows="3" v-model="form.desc" autocomplete="off" placeholder="请输入描述"></el-input>
        </el-form-item>
        <el-form-item label="建议" :label-width="formLabelWidth">
          <el-input type="textarea" rows="3"  v-model="form.recommend" autocomplete="off" placeholder="请输入建议"></el-input>
        </el-form-item>
        <el-form-item label="推荐书单" :label-width="formLabelWidth">
<!--          <el-button type="primary" @click="GoodsVisible=true" size="small">添加书单</el-button>-->
          <el-button type="primary" @click="BookVisible=true" size="small">添加书单</el-button>
          <div class="goods-box">
            <div class="item" v-for="(item,index) in form.book" :key="index">
              <img class="img" :src="item.img">
              <div class="name">{{item.title}}</div>
              <div class="close" @click="delGoods(index)"><i class="el-icon-error"></i></div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveRec" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <div class="fix-btn2">
      <el-button type="primary" @click="saveIt" size="small">保存推荐</el-button>
    </div>
    <el-dialog title="编辑推荐" :visible.sync="GoodsVisible" custom-class="dialog">
      <goods-list :type="1" :goodsType="1" :selectFunc="selectFunc"></goods-list>
    </el-dialog>
    <el-dialog title="编辑推荐" :visible.sync="BookVisible" custom-class="dialog">
      <book-list :type="1" :selectFunc="selectFunc"></book-list>
    </el-dialog>
    <el-dialog title="选择选项" :visible.sync="OptionVisible" custom-class="dialog">
      <div class="list" v-for="(item,index) in questionList" :key="index">
        <div class="title">{{index+1}}、{{item.title}}</div>
        <div class="item" v-for="(child,cindex) in item.options" :key="cindex" @click="selectOption(item,child)">
          {{letter[cindex]}}、{{child.title}}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import GoodsList from "../../components/GoodsList";
import BookList from "@/components/BookList";
export default {
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      id: 0,
      dialogFormVisible: false,
      GoodsVisible: false,
      BookVisible: false,
      OptionVisible: false,
      formLabelWidth: '80px',
      dataList: [],
      dataIdx: -1,
      questionList: [],
      form: {
        id: 0,
        t_type: 4,
        content: [],
        desc: '',
        score_title: '',
        score_start: '',
        score_end: '',
        recommend: '',
        book: []
      },
      letter: ['A','B','C','D','E','F','G','H','I','J'],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getBindInfo()
      this.getQuestion()
    }
  },
  mounted() {
  },
  components: {
    GoodsList,
    BookList
  },
  computed: {},
  methods: {
    selectFunc(item) {
      // this.form.book.push({id:item.id,title:item.title,img:item.img})
      this.form.book[0] = {id:item.id,title:item.title,img:item.img}
      this.GoodsVisible = false
      this.BookVisible = false
    },
    delGoods(idx) {
      this.form.book.splice(idx,1)
    },
    selectOption(item,child) {
      var data = {id:item.id,title:item.title,tid:child.id,ttitle:child.title}
      var isTrue = false
      this.form.content.forEach(res => {
        if(res.id==data.id && res.tid==data.tid) {
          isTrue = true
        }
      })
      if(isTrue) {
        this.fail('已选过当前选项，无需重复选择')
      } else {
        this.form.content.push(data)
        this.OptionVisible = false
      }
    },
    delOption(idx) {
      this.form.content.splice(idx,1)
    },
    addQuestion() {
      this.dataIdx = -1
      this.form = {
        id: 0,
        t_type: 4,
        content: [],
        desc: '',
        recommend: '',
        book: []
      }
      this.dialogFormVisible = true
    },
    editRec(idx) {
      this.dataIdx = idx
      this.form = JSON.parse(JSON.stringify(this.dataList[idx]))
      this.dialogFormVisible = true
    },
    delRec(idx) {
      this.dataList.splice(idx,1)
    },
    saveRec() {
      if(this.form.t_type<3 && this.form.content.length<1) {
        this.fail('请添加选项')
        return false
      }
      if(this.dataIdx==-1) {
        this.dataList.push(this.form)
      } else {
        this.dataList[this.dataIdx] = this.form
      }
      this.dialogFormVisible = false
    },
    getBindInfo() {
      this.$api.test.testBindGet({id: this.id}, res => {
        if(res.errcode==0) {
          this.dataList = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getQuestion() {
      this.$api.test.testQuestionIndex({id: this.id}, res => {
        if(res.errcode==0) {
          this.questionList = res.data.questionList
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    saveIt() {
      var params = {
        id: this.id,
        data: JSON.parse(JSON.stringify(this.dataList))
      }
      this.$api.test.testBindSave(params, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.$router.push({name: 'test_index'})
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
  width: 70%;
}
.fix-btn {
  position: fixed;
  top: 90px;
}
.fix-btn2 {
  position: fixed;
  bottom: 50px;
}
.question {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
}
.question .option {
  margin-top: 10px;
  padding-left: 20px;
}
.question .intro {
  margin: 5px 0;
}
.question .opt {
  position: absolute;
  top: 30px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 100;
}
.question .goods-box {
  margin-right: 160px;

}
.list {

}
.list .title {
  font-size: 14px;
  margin-bottom: 6px;
}
.list .item {
  font-size: 12px;
  margin-bottom: 4px;
  padding: 5px 0 5px 20px;
  cursor: pointer;
}
.list .item:hover {
  background-color: #999;
}
</style>
